//
// Form editors.scss
//

// CK Editor

.ck {
    &.ck-reset_all, &.ck-reset_all *{
        color: var(--#{$prefix}body-color) !important;
    }
    
    &.ck-editor__main>.ck-editor__editable:not(.ck-focused){
        border-color: $input-border-color !important;
    }
    &.ck-toolbar {
        background-color: var(--#{$prefix}light) !important;
        border: 1px solid $input-border-color !important;

        &.ck-toolbar_grouping > .ck-toolbar__items {
            flex-wrap: wrap !important;
        }

        .ck.ck-toolbar__separator {
            background: transparent !important;
        }
    }

    &.ck-editor__main > .ck-editor__editable {
        border-top: 0 !important;
        background-color: $card-bg !important;
        border-color: $input-border-color !important;
        box-shadow: $box-shadow !important;
    }

    &.ck-dropdown__panel,
    &.ck-list {
        background: $dropdown-bg !important;
        border: 1px solid $dropdown-border-color !important;
        box-shadow: $box-shadow !important;
    }

    &.ck-dropdown {
        .ck-dropdown__panel {
            &.ck-dropdown__panel_ne,
            &.ck-dropdown__panel_se {
                left: 0;
                right: auto !important;
            }
        }
    }

    &.ck-editor__editable_inline {
        &[dir="rtl"] {
            text-align: left !important;
        }
    }
}

.ck-editor__editable {
    min-height: 245px !important;
}

.ck,
a.ck {
    &.ck-button {
        &.ck-on:not(.ck-disabled) {
            &:hover,
            &:active {
                background: $light !important;
                box-shadow: none !important;
            }
        }

        &:focus,
        &:active {
            background: $light !important;
            border-color: $light !important;
        }
    }
}

.ck.ck-tooltip .ck-tooltip__text {
    background: $dark !important;
    color: $light !important;
}

.ck.ck-toolbar .ck.ck-button.ck-on,
a.ck.ck-button.ck-on,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
    background: var(--#{$prefix}light) !important;
}

.ck.ck-list__item .ck-button .ck-button__label{
   font-family: 'Be Vietnam Pro', sans-serif;
}

.ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover, .ck.ck-button.ck-on, a.ck.ck-button.ck-on{
    background-color: var(--#{$prefix}light) !important;
  }
  

// Tinymce

.tox-tinymce {
    border: 1px solid $input-border-color !important;
  }


  
  .tox {
    .tox-collection__item {
        color: $dropdown-link-color !important;
    }

  
    .tox-menu.tox-collection.tox-collection--list.tox-selected-menu,
    .tox-collection--toolbar.tox-collection--toolbar-lg.tox-selected-menu,
    .tox-swatches-menu.tox-selected-menu {
        box-shadow: $box-shadow-sm !important;
        animation-name: DropDownSlide !important;
        animation-duration: 0.3s !important;
        animation-fill-mode: both !important;
        margin: 0 !important;
        position: absolute !important;
        z-index: 1000 !important;
        padding: 0.5rem 0 !important;
        background-color: $dropdown-bg !important;
        border: $dropdown-border-width solid $dropdown-border-color !important;
        border-radius: 0.25rem !important;
    }
  
    .tox-collection--list .tox-collection__group {
        border-color: $border-color !important;
    }
  
    .tox-collection--list {
        .tox-collection__item--active {
            color: $dropdown-link-hover-color !important;
            background-color: $dropdown-link-hover-bg !important;
        }
    }
  
    .tox-collection__group-heading {
        color: $dropdown-link-hover-color !important;
        background-color: $dropdown-link-hover-bg !important;
    }
  
    .tox-statusbar {
        border-top: 1px solid var(--#{$prefix}light) !important;
    }
  
    .tox-menubar,
    .tox-edit-area__iframe,
    .tox-statusbar {
        background-color: $card-bg !important;
        background: none !important;
    }
  
    .tox-mbtn {
        color: var(--#{$prefix}secondary-color) !important;
  
        &:hover:not(:disabled):not(.tox-mbtn--active) {
            background-color: $light !important;
        }
    }
  
    .tox-tbtn {
        &:hover {
            background-color: $light !important;
        }
    }
  
    .tox-toolbar,
    .tox-toolbar__overflow,
    .tox-toolbar__primary {
        background: var(--#{$prefix}light) !important;
    }
  
    .tox-toolbar__primary {
        border-top-color: var(--#{$prefix}light) !important;
    }
  
    .tox-tbtn {
        color: var(--#{$prefix}body-color) !important;
  
        svg {
            fill: var(--#{$prefix}body-color) !important;
        }
    }
  
    .tox-edit-area__iframe {
        background-color: var(--#{$prefix}white) !important;
    }
  
    .tox-statusbar a,
    .tox-statusbar__path-item,
    .tox-statusbar__wordcount {
        color: var(--#{$prefix}secondary-color) !important;
    }
  
    &:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
        border-right: 1px solid var(--#{$prefix}light) !important;
    }
  
    .tox-collection--toolbar {
        .tox-collection__item--active {
            background-color: var(--#{$prefix}border-color) !important;
        }
    }
  }

  .tox .tox-tbtn:hover{
    background-color:  var(--#{$prefix}light) !important;
  }

  
[dir="rtl"] {
    .ck.ck-toolbar > .ck-toolbar__items {
        flex-direction: row-reverse;
    }
}
