// 
// Extras pages.scss
//


/*********************
    Maintenance
**********************/ 

.maintenance-cog-icon{
    .cog-icon{
        position: relative;
        bottom: 24px;
        right: 14px;    
    }
}


// pricing


.pricing-badge {
    position: absolute;
    top: 0;
    z-index: 9;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
    .badge {
      float: right;
      transform: rotate(45deg);
      right: -67px;
      top: 17px;
      position: relative;
      text-align: center;
      width: 200px;
      font-size: 13px;
      margin: 0;
      padding: 7px 10px;
      font-weight: 500;
      color: $primary;
      background: $white;
    }
}



// PRICING TAB

.pricing-tab-box{
  .nav-link{
    padding: 16px 20px;
    border: 1px solid var(--#{$prefix}border-color);
    background: $card-bg;
    &.active{
      border: 1px solid rgba(var(--bs-primary-rgb), 1) !important;
      padding: 16px 20px;
      background: $card-bg;
      i{
        color: rgba(var(--bs-primary-rgb), 1);
        &:before{
          content: "\e9a8";
        }
      }
      h1{
        color: rgba(var(--bs-primary-rgb), 1);
      }
    }
   
  }
}

.pricing-table-bg{
  background: $card-bg;
}

// Error PAge

.error-title{
  text-transform: uppercase;
  background: repeating-linear-gradient(45deg, rgba(var(--bs-primary-rgb), 1), rgba(var(--bs-primary-rgb), 1) 20px, $success 40px, $success 10px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12rem;
  line-height: .8;
  position: relative;
}
